import * as React from "react";
import {useEffect, useState, useRef} from "react";
import Layout from "../components/layout"
import Seo from "../components/seo"
import SolaiNft from "../components/solai-nft";
import ShowNewClaimedNfts from "../components/show-newclaimed-nfts";
import Countdown from 'react-countdown';
import {Link, navigate} from "gatsby";
import Modal from 'react-modal';

import logo_k011 from "../images/k011_logo_black.svg";
import logo_tensor from "../images/logo_tensor.svg";
import logo_solana from "../images/logo_solana_color.svg";
import logo_dreamcult from "../images/logo_dreamcult.svg";
import BannerImage from "../images/banner_cyborgcultures.png";
import loadingGif from "../images/loading.gif";
import close_modal from "../images/close_modal.svg"
import { getCollectionByOwner, mint, finalize, claim } from '../api/api';

const CyborgCulturIra: React.FC = () => {

    //26th Sep at 7pm CET
    //const [dropDate, setDropDate] = useState(1695747600000);
    //Sun Sep 01 2024 00:00:00 GMT+0200 
    const [dropDate, setDropDate] = useState(Date.now());
    const [mintEndDate, setMintEndate] = useState(Date.now()+86400000);
    const [totalDrops, setTotalDrops] = useState(0);
    //const [remainingPercentage, setRemainingPercentage] = useState(0);
    const [progress, setProgress] = useState(0);

    const [mintedData, setMintedData] = useState({});

    const [isLive, setIsLive] = useState(true);
    const [isSoldOut, setIsSoldOut] = useState(false);

    // set to false when soldout
    const [showButton, setShowButton] = useState(true);
    const collectionOwner = "7W2vzzJ1FJmK3F7sct1wbWQuzGa8APeUp8ocHr6uJcmF"; //  "DEVJb1nq3caksGybAFxoxsYXLi9nyp8ZQnmAFmfAYMSN";//"BdP8KyTAFmEF2L8DTufgYxQKr3V9ZBBgmfBRcqnFke1t";//"7wK3jPMYjpZHZAghjersW6hBNMgi9VAGr75AhYRqR2n"; //   "DEVJb1nq3caksGybAFxoxsYXLi9nyp8ZQnmAFmfAYMSN";//"6KuX26FZqzqpsHDLfkXoBXbQRPEDEbstqNiPBKHNJQ9e";
    const collectionName = "Cyborg culture";
    const collectionImageUrl = "https://ira.0kai-cdn.com/image/1/#token_id/1200/800.png";
    const collectionUrl = "/cyborg-culture/";
    const collectionRandomId = Math.floor(Math.random() * 100000);
    const [collection, setCollection] = useState(null);

    useEffect(() => {
        if (Date.now() >= dropDate) {
            setIsLive(true);
        }
       
    }, []);

    useEffect(() => {
        const fetchCollection = async () => {
            try {
                const collectionData = await getCollectionByOwner(collectionOwner);
                const maxSupply = parseInt(collectionData.collection.maxSupply, 16); 
                const decimalTimestampStartDate = parseInt(collectionData.collection.saleStartTime, 16) * 1000;                
                const decimalTimestampEndDate = parseInt(collectionData.collection.saleEndTime, 16) * 1000;
                const mintCount = parseInt(collectionData.collection.mintCount, 16);
                if (mintCount == maxSupply) {
                    setIsSoldOut(true);
                }
                setCollection(collectionData.collection);
                setTotalDrops(maxSupply);
                console.log(decimalTimestampEndDate);
                setMintEndate(decimalTimestampEndDate);
                console.log(decimalTimestampStartDate);
                console.log(Date.now() );
                setDropDate(decimalTimestampStartDate);
                setProgress(mintCount);
            } catch (error) {
                console.error('Error fetching collection:', error);
            }
        };

        fetchCollection();

    }, [mintedData]);

    const countdownRenderer = ({days, hours, minutes, seconds, completed}) => {
        if (completed) {
           // setIsLive(true);
            if (showButton) {
                return <SolaiNft claimNftCallback={claimNftCountCallback} collectionOwner={collectionOwner} collectionId={collectionRandomId} collection={collection} collectionName={collectionName} collectionImageUrl={collectionImageUrl} />
            } else {
                return <button className='button black mint'><img alt="Loading" className="loading" src={loadingGif}/>
                </button>
            }

        } else {
            return <div className='button countdown'>
                <div>Live
                    in <span>{days} days</span><span>{hours}h</span>:<span>{minutes}m</span>:<span>{seconds}s</span>
                </div>
            </div>;
        }
    };

    const claimNftCountCallback = (mintedDataCallBack) => {
        setProgress((progress + 1));
        console.log(["Set progress", progress]);
        setMintedData(mintedDataCallBack);
    }

    const remainingPercentage = () =>{
       return ((progress / totalDrops) * 100).toFixed(2);
    }
    /* MODAL */
    const customStyles = {
        content: {top: '30%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', background: '#FFFFFF', zIndex: '999'},
        overlay: {zIndex: 1000, backgroundColor: 'rgba(0, 0, 0, 0.75)'}
    };

    Modal.setAppElement('#___gatsby');

    const [modalIsOpen, setModalIsOpen] = useState(true);

    function openModal() {
        setModalIsOpen(true);
    }

    function afterOpenModal() {
    }

    function closeModal() {
        setModalIsOpen(false);
        navigate(`/cyborg-culture/${mintedData.id}`);
    }

    function closeModalBefore() {
        setModalIsOpen(false);
    }

    return (
        <Layout>
            <div className='container clearfix'>
                <section className='section-banner populus' style={{backgroundImage: `url(${BannerImage})`}}>
                    <div className='container clearfix flex'></div>
                </section>
            </div>
            <section className='section-title collection populus'>
                <Seo title="Playground - Cyborg Culture by Ira Greenberg"/>
                <div className='container clearfix flex'>
                    <div className='col50 left info'>
                        <div className='clearfix tags'>
                            <Link to='/collections/' className='button black left'>Collection</Link>
                            <a className='button black left'> {progress?progress:"0"} minted</a>
                        </div>
                        <h1>Cyborg Culture<br/><span>by Ira Greenberg</span></h1>
                        <p className='big'>Ira Greenberg introducing his latest project, titled "Cyborg Culture." This exploration delves into the concept of a cyborgian emergence, reflecting the evolving landscape shaped by advancing augmentation and digital mediation. The artwork seeks to capture this intersection of humanity and technology, presenting it with a sense of detached ambivalence. By steering clear of overt dystopian or utopian narratives, Ira aims to provoke thought and invite viewers to ponder the implications of our increasingly cyborgian reality.</p>
                        <p className='big'>From a technical standpoint, the creation process involves generating a multitude of source images for image-to-image input. This method ensures that the collection remains both highly cohesive and richly varied. Each piece will contribute to a long-form narrative, weaving together the diverse threads of our technologically augmented existence.</p>
                    </div>  
                    <div className='col50 left mint-actions'>
                        <div className='mint-box'>
                            <div className='solana-icon'>
                                <h4>Secured By</h4>
                                <a href="https://solana.com/" target="_blank"><img className='partner-logo solana' src={logo_solana}/></a>
                            </div>
                            <h2><span>price</span>{collection?(collection.price).toFixed(2):"X"} SOL</h2>
                            {
                                    mintEndDate > Date.now() ?
                                    (   <>
                                        <p>{remainingPercentage()}% minted ({progress}/{totalDrops})</p>
                                        <div className='progress-bar'>
                                            <div className='progress' style={{width: `${remainingPercentage()}%`}}></div>
                                        </div>
                                        {isSoldOut ? (
                                            <button className='button black mint'>SOLD OUT</button>
                                        ) : (
                                            <Countdown date={dropDate} renderer={countdownRenderer} />
                                        )}                                        
                                        </>
                                    )
                                    : (
                                        <button className='button black mint'>Collection is now closed</button>
                                    )
                            }
                        </div>                        
                    </div>
                </div>
                 
                <ShowNewClaimedNfts collectionOwner={collectionOwner} collectionImageUrl={collectionImageUrl} collectionName={collectionName} collectionUrl={collectionUrl} />
            
            </section>
            
            {mintedData.address &&
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModalBefore}
                    style={customStyles}
                    portalClassName="minting-modal"
                    contentLabel="New Mint Modal"
                >
                    <div id="minting">
                        <a className='close-modal' onClick={closeModalBefore}><img src={close_modal}/></a>
                        <div className="minting-step-info">
                            <h3>Congratulations!</h3>
                            <p>You minted:</p><p className='big'>{mintedData.name}</p>
                            <div className='minted-image'><img src={mintedData.address} alt='Image rendered...' /></div>
                            <button className='button black close' onClick={closeModal}>See your artwork!</button>
                        </div>
                    </div>
                </Modal>
            }
        </Layout>
    );
};

export default CyborgCulturIra;